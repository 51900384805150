import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";
import { Col, Row } from "react-bootstrap";
import { CasinoProviders } from "../lib/data";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import Betfair from "../assets/images/providers/betfair.png";
import PaymentLogo1 from "../assets/images/payments/Visa.webp";
import PaymentLogo2 from "../assets/images/payments/masterCard.webp";
import PaymentLogo3 from "../assets/images/payments/ApplePay.webp";
import PaymentLogo4 from "../assets/images/payments/Gpay.webp";
import PaymentLogo5 from "../assets/images/payments/Openbanking.webp";
import ProviderLogo1 from "../assets/images/providers/Evolution.jpg";
import ProviderLogo2 from "../assets/images/providers/Ezugi.jpg";
import ProviderLogo3 from "../assets/images/providers/Supernowa.jpg";
import ProviderLogo4 from "../assets/images/providers/XPG.jpg";
import ProviderLogo5 from "../assets/images/providers/Vivogaming.jpg";
import ProviderLogo7 from "../assets/images/providers/OneTouch.jpg";
import ProviderLogo8 from "../assets/images/providers/Pragmaticplay.jpg";
import ProviderLogo9 from "../assets/images/providers/Onlyplay.jpg";
import PaymentLogo6 from "../assets/images/payments/Bitcoin.webp";
import PaymentLogo7 from "../assets/images/payments/Intreact.webp";
import PaymentLogo8 from "../assets/images/payments/Blik.webp";
import PaymentLogo9 from "../assets/images/payments/plus18.webp";
import PaymentLogo10 from "../assets/images/payments/Fg.webp";
import Aviator from "../assets/images/games-icon/Aviator.png";
import Ezugi from "../assets/images/games-icon/headicon/ezugi.png";
import Sports from "../assets/images/games-icon/menu.webp";
import sportsicon2 from "../assets/images/icons/customer-support.png";
import Evolution from "../assets/images/games-icon/headicon/evolution.png";
import HomeIcon from "../assets/images/games-icon/headicon/house.png";

import Casinoicon from "../assets/images/icons/casino.webp";
import livecasino from "../assets/images/icons/Livecasino.webp";
import sports from "../assets/images/icons/AllSports.webp";

import Gcb from "../assets/images/icons/gcb.png";
import { handleLeftSideBar } from "../redux/app/actions";

const footerPages = [
  "home",
  "sportsbook",
  "casino/evolution",
  "casino/ezugi",
  "gatewaylist",
];

const casinoProvidersGames = [
  {
    code: "evolution",
    name: "Evolution",
    imgUrl: ProviderLogo1,
    type: "evolution",
    status: 1,
  },
  {
    code: "ezugi",
    name: "Ezugi",
    imgUrl: ProviderLogo2,
    type: "ezugi",
    status: 2,
  },
  {
    code: "supernowa",
    name: "Supernowa",
    imgUrl: ProviderLogo3,
    type: "supernowa",
    status: 3,
  },
  {
    code: "xpg",
    name: "XPG",
    imgUrl: ProviderLogo4,
    type: "xpg",
    status: 4,
  },
  {
    code: "vivo",
    name: "Vivo",
    imgUrl: ProviderLogo5,
    type: "vivo",
    status: 5,
  },
  {
    code: "onetouch",
    name: "Onetouch",
    imgUrl: ProviderLogo7,
    type: "onetouch",
    status: 7,
  },
  {
    code: "pragmatic",
    name: "Pragmatic Play",
    imgUrl: ProviderLogo8,
    type: "pragmatic",
    status: 8,
  },
  {
    code: "onlyplay",
    name: "Only Play",
    imgUrl: ProviderLogo9,
    type: "onlyplay",
    status: 9,
  },
];

const Footer = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const dispatch = useDispatch();
  const { showLeftSideBar } = useSelector((state) => state.app);

  useEffect(() => {
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <footer className="footer">
      <div className="FoooterLinks">
        <div className="LinksSection">
          <div className="LinksFirstSection">
            <div className="LogoFooter">
              <img src={appDetails?.LOGO_URL} alt="Logo" />
              <p>© 2024 bigwin.com</p>
              <p>All Rights Reserved.</p>
            </div>
            <div>
              <ul>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "#"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    <div className="img-box">
                      <img src={Whatsapp} alt="Whatsapp Icon" />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <div className="img-box">
                      <img src={Telegram} alt="Telegram Icon" />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "#"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <div className="img-box">
                      <img src={Instagram} alt="Instagram Icon" />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <div className="img-box">
                      <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="LinksSection">
          <h4>CASINO</h4>
          <ul>
            <li>
              <a href="">CASINO HOME</a>
            </li>
            <li>
              <a href="">LIVE CASINO</a>
            </li>
          </ul>
        </div> */}
        <div className="LinksSection">
          <h4>SPORTS</h4>
          <ul>
            <li>
              <a href="/exchange/Inplay">INPLAY</a>
            </li>
            <li>
              <a href="/exchange/Soccer">SOCCER</a>
            </li>
            <li>
              <a href="/exchange/Tennis">TENNIS</a>
            </li>
            <li>
              <a href="/exchange/Cricket">CRICKET</a>
            </li>
            <li>
              <a href="/exchange/Horse%20Racing">HORSE RACING</a>
            </li>
            <li>
              <a href="/exchange/Grey%20Racing">GREYHOUND RACING</a>
            </li>
          </ul>
        </div>
        <div className="LinksSection">
          <h4>LEGAL</h4>
          <ul>
            <li>
              <a href="/contactus">CONTACT US</a>
            </li>
            <li>
              <a href="/privacypolicy">PRIVACY POLICY</a>
            </li>
            <li>
              <a href="/responsiblegame">RESPONSIBLE GAMING</a>
            </li>
          </ul>
        </div>
        <div className="LinksSection">
          <h4>ABOUT</h4>
          <ul>
            <li>
              <a href="/fairplay">FAIR PLAY</a>
            </li>
            <li>
              <a href="/gamesrule">GAMES RULES</a>
            </li>
            <li>
              <a href="/terms">TERMS & CONDITIONS</a>
            </li>
            <li>
              <a href="/">PROMOTION</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="FooterContent">
        <p>
          Bigwin.com is operated by SIMBA N.V., a company incorporated under the
          laws of Curaçao with number 164834 and address Zuikertuintjeweg Z/N
          (Zuikertuin Tower), Willemstad, Curacao. SIMBA N.V. and by Sand Tech
          Services Ltd, a company registered in Cyprus with number 452264 and
          address 1 Avlonos Street, Maria House, 4th Floor 1075, Nicosia,
          Cyprus. SIMBA N.V. is licensed by the Curaçao Gaming Control Board to
          offer games of chance under license number OGL/2024/1788/1030 in
          accordance with the National Ordinance on Offshore Games of Hazard
          (Landsverordening buitengaatse hazardspelen, P.B. 1993, no. 63)
          (NOOGH).
        </p>
        <img src={Gcb} alt="" />
      </div>
      <div className="FooterPaymentSectiom">
        <div className="providers_logo payment_logo d-flex flex-wrap justify-content-center">
          <a href="/casino/evolution" className="logo_box">
            <img src={PaymentLogo1} alt="Provider Logo" />
          </a>
          <a href="/casino/pragmatic" className="logo_box payment-height">
            <img src={PaymentLogo2} alt="Provider Logo" />
          </a>
          <a href="casino/ezugi" className="logo_box">
            <img src={PaymentLogo3} alt="Provider Logo" />
          </a>
          <a href="/casino/supernowa" className="logo_box">
            <img src={PaymentLogo4} alt="Provider Logo" />
          </a>
          <a href="/casino/onetouch" className="logo_box payment-height">
            <img src={PaymentLogo5} alt="Provider Logo" />
          </a>
          <a href="/casino/supernowa" className="logo_box">
            <img src={PaymentLogo6} alt="Provider Logo" />
          </a>
          <a href="javascript:void(0)" className="logo_box payment-height">
            <img src={PaymentLogo7} alt="Provider Logo" />
          </a>
          <a href="javascript:void(0)" className="logo_box payment-height">
            <img src={PaymentLogo8} alt="Provider Logo" />
          </a>
          <a href="javascript:void(0)" className="logo_box payment-height">
            <img src={PaymentLogo9} alt="Provider Logo" />
          </a>
          <a href="javascript:void(0)" className="logo_box payment-height">
            <img src={PaymentLogo10} alt="Provider Logo" />
          </a>
        </div>
      </div>
      <div className="inner-content">
        <div className="container">
          <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-lg-none">
            <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
              <li>
                <a
                  className={activePage === "sportsbook" ? "active" : ""}
                  href="#"
                  onClick={() => dispatch(handleLeftSideBar(!showLeftSideBar))}
                >
                  <img src={Sports} alt="sports" />
                  <span>Menu</span>
                </a>
              </li>
              <li>
                <a href={CasinoProviders["evolution"]?.href}>
                  <img src={Casinoicon} alt="evolution" />
                  <span>Casino</span>
                </a>
              </li>
              <li>
                <a
                  className={activePage === "home" ? "active" : ""}
                  href="/live-games"
                >
                  <img src={livecasino} alt="home" />
                  <span>Live Casino</span>
                </a>
              </li>
              <li>
                <a href="/exchange/Inplay">
                  <img src={sports} alt="ezugi" />
                  <span>Exchange</span>
                </a>
              </li>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <img src={sportsicon2} alt="ezugi" />
                  <span>Support</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
